
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '@bt-react/styles/colours';

.background-task {background-color: $task}
.background-primary-bt {background-color: $primary-text}
.background-secondary {background-color: $secondary}
.background-brighttalk {background-color: $primary-alt;}
.background-white {background-color: $background}
.background-task {background-color: $task}
.background-london-grey {background-color: $london-grey}
.background-brighttalk-dark {background-color: $primary;}
.background-danger {background-color: $danger-alt;}
.background-tertiary {background-color: $tertiary;}
.background-dark-grey {background-color: $secondary-alt;}
.background-pastel-green {background-color: $highlighted-pastel;}
.background-light-grey {background-color: $highlighted-grey;}
.background-sky-blue {background-color: $bt-light-blue;}
.background-sky-blue {background-color: $bt-light-blue;}
.background-dark-orange {background-color: $bt-red;}
.background-neutral {background-color: $bt-indigo-neutral;}

.text-task {color: $task}
.text-indigo { color: $bt-indigo; }
.text-primary-bt {color: $primary-text}
.text-secondary {color: $secondary}
.text-brighttalk {color: $primary-alt}
.text-white {color: $background}
.text-task {color: $task}
.text-london-grey {color: $london-grey}
.text-brighttalk-dark {color: $primary;}
.text-danger {color: $danger-alt;}
.text-tertiary {color: $tertiary;}
.text-content-blue {
  color: $content-blue;
}
.text-light-grey {color: $highlighted-grey;}
.text-green {color: $green;}
.text-dark-danger{color: #c00}
.text-sky-blue {color: $bt-light-blue;}
.text-dark-orange {color: $bt-red;}

.button-task {background-color: $task}
.button-primary-bt {background-color: $primary-text}
.button-content-blue {
  background-color: $content-blue;
  color: #FFF;
}
.button-sky-blue {
  background-color: $bt-light-blue;
  color: #FFFFFF;
}
.button-ad-btn {
  background-color: #FFFFFF;
  color: $bt-light-blue;
}
.button-promote-btn {
  background: $bt-lightest-grey 0% 0% no-repeat padding-box;
  color: $primary-text;
}
.button-neutral {
  background: $bt-default 0% 0% no-repeat padding-box;
  color: $primary-text;
}
.button-secondary {
  background-color: $secondary;
  color: #FFF;
  &> svg {
    color: inherit;
  }
}
.button-brighttalk {
  background-color: $primary-alt;
  color: #FFF;
}
.button-white {background-color: $background}
.button-task {background-color: $task}
.button-london-grey {background-color: $london-grey}
.button-brighttalk-dark {
  background-color: $primary;
  color: #FFF;
}
.button-dark-grey {
  background-color: $secondary-alt;
  color: #FFF;
}
.button-danger {background-color: $danger-alt;}
.button-tertiary {background-color: $tertiary;}
.button-light-grey {background-color: $highlighted-grey;}
.button-purple {
  background-color: $purple;
  color: #FFF;
}
.button-black {
  background-color: $black;
  color: #FFF;
}

.button-bright {
  background: $background 0% 0% no-repeat padding-box;
}

.button-dark-orange {
  background: $bt-red 0% 0% no-repeat padding-box;
  color: $bt-default;

  &:focus {
    box-shadow: none !important;
  }

}
