$font-family: 'BrightTALK', arial, helvetica, sans-serif !default;
$font-weight: lighter;
$btn-font-weight: normal;
$text-shadow-colour: #666 !default;
$font-display: auto !default;

$font-size: 16px !default;
$font-size-small: ceil($font-size * .85) !default; // ~13px
$font-size-medium: ceil($font-size * 1.1) !default; // ~18px
$font-size-large: ceil($font-size * 1.25) !default; // ~20px
$font-size-larger: ceil($font-size * 1.5) !default; // ~24px
$font-size-x-large: ceil($font-size * 1.57) !default; // ~25px
$font-size-xx-large: ceil($font-size * 2) !default; // ~32px
