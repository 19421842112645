.modal-header {

  border: 0;
  display: block;
}

.modal-title {

  background: $background-alt;
  font-size: $font-size-x-large;
  font-weight: normal;
  padding: 5px;
  text-align: center;

  .main-message {

    color: $task;
  }
}


.modal-body {

  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
