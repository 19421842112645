.text-task {color: $task}
.text-primary-bt {color: $primary-text}
.text-secondary {color: $secondary}
.text-brighttalk {color: $primary-alt}
.text-white {color: $background}
.text-task {color: $task}
.text-london-grey {color: $london-grey}
.text-light-grey {color: $light-grey}
.text-brighttalk-dark {color: $primary;}
.text-danger {color: $danger-alt;}
.text-tertiary {color: $tertiary;}
.text-light-green {color: $light-green}
.text-green {color: $green}