$profile-menu-side-margin: 20px;
$profile-menu-option-line-height: 2.4;
$profile-menu-button-border: 1px solid $tertiary;

.profile-area {

  position: relative;
  width: 58px;

  .profile-menu-toggle-button,
  .profile-area-user-image-upload-icon-wrapper {

    height: 58px;
    padding: 3px 10px 17px;
    width: 58px;
  }

  .profile-menu-toggle-button {

    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
    position: relative;

    &.profile-menu-close-button {

      background: $background;
    }

    .profile-area-user-image {

      border: $profile-menu-button-border;
      height: 38px;
      width: 38px;
    }

    .profile-area-notification-icon {

      bottom: 9px;
      color: $task;
      font-size: 18px;
      left: 5px;
      position: absolute;
    }
  }

  .profile-area-user-image-upload-icon-wrapper {

    text-align: center;

    .profile-area-user-image-upload-icon {

      background: $light-contrast;
      border: $profile-menu-button-border;
      font-size: $loading-icon-size;
      line-height: 38px;
    }
  }

  .profile-menu {

    background: $background;
    border-bottom: 3px solid $primary-alt;
    display: none;
    font-size: $font-size-small;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: left;
    top: 59px;
    width: 250px;
    z-index: 1;

    .profile-menu-user-name {

      display: block;
      font-size: $font-size-medium;
      font-weight: bold;
      margin: 20px $profile-menu-side-margin;
    }

    .profile-menu-options {

      font-size: $font-size;
      position: relative;
    }

    .profile-menu-option {

      list-style: none;
      margin: 0;

      &:last-child {

        .profile-menu-option-link.profile-menu-option-link-text {

          border-bottom: 0;
          line-height: 2 * $profile-menu-option-line-height;
        }
      }

      .profile-menu-option-link {

        box-sizing: border-box;
        color: $primary-text;
        display: block;
        line-height: 2;
        text-decoration: none;

        &:hover {

          color: $primary;
        }

        .profile-menu-option-link-text {

          border-bottom: 1px solid $tertiary;
          display: block;
          line-height: $profile-menu-option-line-height;
          margin: 0 $profile-menu-side-margin;
        }
      }
    }
  }
}
