.dropdown {

  &.full-width-xs {

    .btn {

      @extend .full-width-xs;
    }

    .dropdown-menu {

      width: 100%;
    }
  }

  .fa {

    padding-left: 5px;
  }

  .dropdown-menu {

    .fa {

      float: left;
      font-size: 22px;
      margin-right: 15px;
      padding-left: 0;
      width: 20px;
    }

    a {

      color: $primary-text;
      display: block;
      padding: 3px 20px;

      &:hover,
      &:focus {

        background-color: $tertiary;
        text-decoration: none;
      }
    }
  }
}

@include media-breakpoint-up(sm) {

  .dropdown {

    &.full-width-xs {

      .btn,
      .dropdown-menu {

        width: auto;
      }
    }
  }
}
