.site-footer {

  background: $background;
  color: $secondary-alt;
  font-size: $font-size;
  padding: 20px 0 0;
  text-align: center;

  .site-footer-headline {

    font-size: $font-size-large;
    line-height: 28px;
    margin: 0 0 20px;
    text-align: center;

    .site-footer-headline-link {

      color: $primary;
      text-decoration: none;

      &:hover {

        text-decoration: underline;
      }
    }
  }

  .site-footer-link-list {

    list-style-type: none;
    padding: 0;

    .site-footer-link-list-item {

      margin: 0;

      .site-footer-link {

        border-top: 1px solid $background;
        color: $secondary-alt;
        display: block;
        padding: 12px;
        text-decoration: none;

        &:hover {

          color: $primary;
          text-decoration: underline;
        }
      }
    }
  }

  .site-footer-copyright {

    border-top: 1px solid $background;
    margin: 0;
    padding: 12px;
  }
}

@include media-breakpoint-up(md) {

  .site-footer {

    font-size: $font-size-small;
    padding: 25px;

    .site-footer-headline {

      line-height: 1;
      margin: 0 0 65px;
    }

    .site-footer-link-list {

      @include clearfix;

      margin: 0 auto 65px;

      .site-footer-link-list-item {

        display: inline-block;
        padding: 0 17px;

        .site-footer-link {

          border: 0;
          display: inline;
          padding: 0;
        }
      }
    }

    .site-footer-copyright {

      border: 0;
      margin: 0 0 20px;
      padding: 0;
    }
  }
}
