.bootstrap-container {

  *:not(i) {
    font-family: $font-family;
  }

  b,
  strong {
    font-weight: 600;
  }

}
