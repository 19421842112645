.block-tabs {

  display: table;
  list-style: none;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  text-align: center;

  .block-tab {

    display: table-cell;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 1%;

    &.block-tab-active {

      background: transparent;
      border-color: $tertiary;
      border-style: solid;
      border-width: 1px 1px 0;
      padding-top: 7px;

      &:first-child {

        border-left: 0;
      }

      &:last-child {

        border-right: 0;
      }

      .block-tab-link {

        background: transparent;
      }
    }
  }

  .block-tab-link {

    background: $tertiary;
    color: $primary-text;
    cursor: pointer;
    display: block;
    font-size: $font-size;
    line-height: 2.5;
    outline: 0;
    text-decoration: none;
  }
}
