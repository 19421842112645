.survey-builder {

  .active-toggle {

    margin: 0 0 8px;
    width: 100%;
  }

  .deleted-questions {

    margin: 10px 0 0 ;
    padding: 0 0 300px;


    .restore-select {

      display: flex;
      flex-direction: row;

      .select-deleted-question {

        flex-grow: 1;
        margin: 0 8px 0 0;
      }

      button {

        flex-basis: auto;

      }
    }
  }
}
