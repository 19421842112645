/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


@import "functions";
@import "variables";
@import "mixins";
.bootstrap-container {
  @import "root";
  @import "reboot";
  @import "type";
  @import "images";
  @import "code";
  @import "grid";
  @import "tables";
  @import "forms";
  @import "buttons";
  @import "transitions";
  @import "dropdown";
  @import "button-group";
  @import "input-group";
  @import "custom-forms";
  @import "nav";
  @import "navbar";
  @import "card";
  @import "breadcrumb";
  @import "pagination";
  @import "badge";
  @import "jumbotron";
  @import "alert";
  @import "progress";
  @import "media";
  @import "list-group";
  @import "close";
  @import "modal";
  @import "tooltip";
  @import "popover";
  @import "carousel";
  @import "utilities";
  @import "print";

  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba($black, 0);
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body-color;
  text-align: left;
  background-color: $body-bg;
}
