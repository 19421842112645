.button-task {background-color: $task}
.button-primary-bt {background-color: $primary-text}
.button-content-blue {
  background-color: $content-blue;
  color: #FFF;
}
.button-secondary {background-color: $secondary}
.button-brighttalk {
  background-color: $primary-alt;
  color: #FFF;
}
.button-white {background-color: $background}
.button-task {background-color: $task}
.button-london-grey {background-color: $london-grey}
.button-brighttalk-dark {
  background-color: $primary;
  color: #FFF;
}
.button-danger {background-color: $danger-alt;}
.button-tertiary {background-color: $tertiary;}