.survey-item-list {
  .survey-item {
    margin-bottom: 10px;
  }
}

.survey-item {

  background: $tertiary;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &.survey-item-dragging {
    opacity: .3;
  }

  .survey-item-details,
  .survey-item-preview {
    padding: 5px 10px;
  }

  .survey-item-details {
    display: flex;

    .survey-item-icon {
      align-self: center;
      margin-right: 10px;
    }

    .survey-item-text {
      align-self: center;
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .survey-item-options {
      display: flex;
      flex: 0 0 auto;

      .survey-item-option {
        background: transparent;
        border: 0;
        border-radius: 25%;
        color: $secondary;
        cursor: pointer;
        flex: 0 0 auto;

        &:hover {
          background: lighten($tertiary, 5%);
        }
      }
    }
  }

  // Every form item that is displayed should blindly have the same drag cursor 
  .survey-item-preview * {
    cursor: inherit;
  }
}

@include media-breakpoint-up(sm) {

  .survey-item-options {
    visibility: hidden;
  }

  .survey-item {

    &:hover {

      .survey-item-options {
        visibility: visible;
      }
    }
  }
}
