.btn {

  -webkit-appearance: none;

  &.btn-toggle {

    @extend .btn;
    @extend .btn-secondary;

    &.active {

      @extend .btn-tertiary;
    }

    &.btn-toggle-on {

      @extend .btn-tertiary;

      [class^='fa-'],
      [class*=' fa-'] {

        &::before {

          //content: fa-content($fa-var-check);
        }
      }
    }
  }

  &.btn-draggable {

    text-align: left;

    &:not(:disabled) {

      &:not(.disabled) {

        @include button-variant($tertiary, darken($tertiary, 5%));

        cursor: move;
      }
    }
  }

  &.btn-switch {

    @include button-variant(fade-out($danger, .8), fade-out($danger, .7));

    color: $danger;
    display: flex;

    &:hover {

      color: lighten($danger, 10%);
    }

    &:disabled {

      &:hover {

        color: $white;
      }
    }

    &.btn-block {

      display: flex;
      text-align: left;

      .btn-switch-text {

        flex: 1 1 auto;
      }
    }

    .btn-switch-text {

      flex: 1 1 100%;
      text-align: left;
    }

    .btn-switch-status {

      align-self: center;
      background: url('#{$style-guide-image-path}/switch-sprite-34x120.png') no-repeat;
      display: inline-block;
      flex: 0 0 34px;
      height: 20px;
      margin-left: 15px;
      width: 34px;
    }

    &.btn-switch-on {

      @include button-variant(fade-out($primary, .8), fade-out($primary, .7));

      color: $primary;

      &:hover {

        color: lighten($primary, 10%);
      }

      &:disabled {

        &:hover {

          color: $white;
        }
      }

      .btn-switch-status {

        background-position: 0 -100px;
      }
    }
  }

  &.full-width-xs {

    width: 100%;
  }
}

.btn-task {

  @include button-variant($task, darken($task, 5%));
}

@include media-breakpoint-up(sm) {

  .btn {

    &.full-width-xs {

      width: auto;
    }
  }
}
