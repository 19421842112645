$avatarSize: 140px;
$innerBorder: 20px;
$avatarInner: $avatarSize - $innerBorder;
$avatarIcon: $avatarInner - $innerBorder;
$borderStyle: rgba(255, 255, 255, 0.11);

@mixin borderSize ($size) {
  height: $size;
  width: $size;
  border-radius: $size;
  display: flex;
}

.avatar {
  @include borderSize($avatarSize);
  background-color: $borderStyle;
}

.avatar__icon {
  @include borderSize($avatarIcon);
  background-color: $background;
  box-shadow: 0 0 0 10px $borderStyle;
  align-self: center;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  color: $secondary;
  font-size: 48px;
}