.card-header {
  border-bottom: 0;
  font-weight: bold;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  flex-flow: column wrap;
  justify-content: space-around;
}

.flex-item {
  margin-top: 10px;
}
