
@mixin clearfix {

  &::before,
  &::after {

    content: ' ';
    display: table;
  }

  &::after {

    clear: both;
  }
}

@mixin gradient($start-color: $background, $end-color: $background-alt, $start-color-position: 0%, $end-color-position: 100%) {

  background: linear-gradient(to bottom, $start-color $start-color-position, $end-color $end-color-position);
}

@mixin text-shadow {

  text-shadow: 0 0 2px $text-shadow-colour;
}
