@font-face {
  font-family: 'BrightTALK';
  font-weight: 100;
  font-display: $font-display;
  src:url("#{$brighttalk-font-path}/Roboto-Thin.eot?#iefix") format("eot"),
    url("#{$brighttalk-font-path}/Roboto-Thin.woff") format("woff"),
    url("#{$brighttalk-font-path}/Roboto-Thin.ttf") format("truetype"),
    url("#{$brighttalk-font-path}/Roboto-Thin.svg") format("svg");
}

@font-face {
  font-family: 'BrightTALK';
  font-weight: 100;
  font-style: italic;
  font-display: $font-display;
  src:url("#{$brighttalk-font-path}/Roboto-ThinItalic.eot?#iefix") format("eot"),
    url("#{$brighttalk-font-path}/Roboto-ThinItalic.woff") format("woff"),
    url("#{$brighttalk-font-path}/Roboto-ThinItalic.ttf") format("truetype"),
    url("#{$brighttalk-font-path}/Roboto-ThinItalic.svg") format("svg");
  }

@font-face {
  font-family: 'BrightTALK';
  font-weight: 200;
  font-display: $font-display;
  src:url("#{$brighttalk-font-path}/Roboto-Thin.eot?#iefix") format("eot"),
    url("#{$brighttalk-font-path}/Roboto-Thin.woff") format("woff"),
    url("#{$brighttalk-font-path}/Roboto-Thin.ttf") format("truetype"),
    url("#{$brighttalk-font-path}/Roboto-Thin.svg") format("svg");
}

@font-face {
  font-family: 'BrightTALK';
  font-weight: 200;
  font-style: italic;
  font-display: $font-display;
  src:url("#{$brighttalk-font-path}/Roboto-ThinItalic.eot?#iefix") format("eot"),
    url("#{$brighttalk-font-path}/Roboto-ThinItalic.woff") format("woff"),
    url("#{$brighttalk-font-path}/Roboto-ThinItalic.ttf") format("truetype"),
    url("#{$brighttalk-font-path}/Roboto-ThinItalic.svg") format("svg");
  }

@font-face {
  font-family: 'BrightTALK';
  font-weight: 300;
  font-display: $font-display;
  src:url("#{$brighttalk-font-path}/Roboto-Light.eot?#iefix") format("eot"),
    url("#{$brighttalk-font-path}/Roboto-Light.woff") format("woff"),
    url("#{$brighttalk-font-path}/Roboto-Light.ttf") format("truetype"),
    url("#{$brighttalk-font-path}/Roboto-Light.svg") format("svg");
  }

@font-face {
  font-family: 'BrightTALK';
  font-weight: 300;
  font-style: italic;
  font-display: $font-display;
  src:url("#{$brighttalk-font-path}/Roboto-LightItalic.eot?#iefix") format("eot"),
    url("#{$brighttalk-font-path}/Roboto-LightItalic.woff") format("woff"),
    url("#{$brighttalk-font-path}/Roboto-LightItalic.ttf") format("truetype"),
    url("#{$brighttalk-font-path}/Roboto-LightItalic.svg") format("svg");
  }

@font-face {
  font-family: 'BrightTALK';
  font-weight: 400;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-Regular.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-Regular.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-Regular.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-Regular.svg') format('svg');
  }

@font-face {
  font-family: 'BrightTALK';
  font-weight: 400;
  font-style: italic;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-Italic.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-Italic.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-Italic.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-Italic.svg') format('svg');
  }
  
@font-face {
  font-family: 'BrightTALK';
  font-weight: 500;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-Medium.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-Medium.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-Medium.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-Medium.svg') format('svg');
}

@font-face {
  font-family: 'BrightTALK';
  font-weight: 500;
  font-style: italic;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-MediumItalic.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-MediumItalic.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-MediumItalic.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-MediumItalic.svg') format('svg');
}

@font-face {
  font-family: 'BrightTALK';
  font-weight: 600;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-Bold.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-Bold.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-Bold.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-Bold.svg') format('svg');
}

@font-face {
  font-family: 'BrightTALK';
  font-weight: 600;
  font-style: italic;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-BoldItalic.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-BoldItalic.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-BoldItalic.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-BoldItalic.svg') format('svg');
}

@font-face {
  font-family: 'BrightTALK';
  font-weight: 700;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-Bold.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-Bold.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-Bold.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-Bold.svg') format('svg');
  }

@font-face {
  font-family: 'BrightTALK';
  font-weight: 700;
  font-style: italic;
  font-display: $font-display;
  src: url('#{$brighttalk-font-path}/Roboto-Bold.eot?#iefix') format('eot'),
    url('#{$brighttalk-font-path}/Roboto-Bold.woff') format('woff'),
    url('#{$brighttalk-font-path}/Roboto-Bold.ttf') format('truetype'),
    url('#{$brighttalk-font-path}/Roboto-Bold.svg') format('svg');
}