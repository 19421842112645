.block-menu {

  font-size: $font-size;

  .block-menu-item {

    background: $tertiary;
    border-bottom: 1px solid $secondary;
    color: $primary-text;
    cursor: pointer;
    display: block;
    font-family: $font-family;
    line-height: 2.5;
    margin: 0 0 1px;
    outline: 0;
    padding: 0 10px;
    text-decoration: none;

    &:hover,
    &.hover {

      background: $background-alt;
    }

    &.block-menu-item-selected {

      background: $background;
      border-width: 0;
      font-weight: bold;
      margin-bottom: 1px;

      &:hover,
      &.hover {

        background: $background;
      }
    }
  }

  &.block-menu-inline {

    font-size: 0;

    .block-menu-item,
    li {

      border: 0;
      display: inline-block;
      font-size: $font-size-small;
    }

    li {

      margin: 0 1px 0 0;

      &:last-child {

        margin: 0;
      }
    }
  }
}
