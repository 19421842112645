.background-task {background-color: $task}
.background-primary-bt {background-color: $primary-text}
.background-secondary {background-color: $secondary}
.background-brighttalk {background-color: $primary-alt;}
.background-white {background-color: $background}
.background-task {background-color: $task}
.background-london-grey {background-color: $london-grey}
.background-brighttalk-dark {background-color: $primary;}
.background-danger {background-color: $danger-alt;}
.background-tertiary {background-color: $tertiary;}
.background-dark-grey {background-color: $secondary-alt;}
.background-pastel-green {background-color: $highlighted-pastel;}
.background-light-grey {background-color: $highlighted-grey;}