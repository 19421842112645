.survey-builder-form {

  .reporting-id-info {

    color: $secondary;
    font-size: 14px;
  }

  .one-answer-msg {

    color: $secondary;
    font-size: 14px;
    padding: 10px;

  }

  .answers {

    .answer {

      padding: 10px 0 0;

      .answer-input {

        display: flex;
        flex-direction: row;

        input {

          flex-grow: 1;
        }

        .delete {

          cursor: pointer;
          flex-basis: auto;
          font-size: 18px;
          padding: 10px 0 0 10px;
        }
      }

    }
  }

}
