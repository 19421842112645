$burger-button-size: 32px;

.burger-button {

  color: $secondary;
  display: inline-block;
  font-size: $burger-button-size;
  height: $burger-button-size;
  line-height: $burger-button-size;

  &:hover {

    text-decoration: none;
  }
}
