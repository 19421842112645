@mixin linear-gradient($from, $to) {
  background: $to;
  background: -moz-linear-gradient(to right, $from 0%, $to 100%);
  background: -webkit-linear-gradient(to right, $from 0%, $to 100%);
  background: -o-linear-gradient(to right, $from 0%, $to 100%);
  background: linear-gradient(to right, $from 0%, $to 100%);
}

$from: rgba(255, 255, 255, 0);
$to: #fff;
$image-border: #ced4da;

.text-no-wrap {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  &::before {
    @include linear-gradient($from, $to);
    content: '';
    height: 125px;
    position: absolute;
    right: 0;
    width: 75px;
    z-index: 1;
  }
}

.webcast-preview {display: flex;}

.margin-bottom {margin-bottom: 5px;}

.thumbnail-preview {
  flex: none;
  margin-right: 10px;
  overflow: hidden;
}
.align-right {text-align: right;}

.top-layer {z-index: 2;}
