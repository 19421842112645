$breadcrumb-separator-width: 15px;
$breadcrumb-strip-height: 25px;
$breadcrumb-button-min-width: 210px;

.breadcrumb-stripe {

  background: $background;
  border-bottom: 1px solid $background;
  border-top: 1px solid $background-alt;
  font-family: $font-family;
  font-size: 0;

  .breadcrumb-link-wrapper {

    display: inline-block;
    position: relative;
    zoom: 1;

    .breadcrumb-link {

      color: $primary-text;
      font-size: $font-size;
      padding-left: 10px;
      text-decoration: none;

      &:hover {

        color: $primary;
      }
    }
  }

  .breadcrumb-wrapper {

    &:first-child {

      .breadcrumb-button {

        background-position: top right;
        padding-left: 10px;
      }

      .breadcrumb-menu {

        margin-left: 0;
        min-width: $breadcrumb-button-min-width - 10;
      }
    }

    &:only-child {

      .breadcrumb-button {

        background-position: bottom right;
      }
    }

    .breadcrumb-button {

      background-color: $background-alt;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAyCAMAAABf28oIAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDE0IDc5LjE1Njc5NywgMjAxNC8wOC8yMC0wOTo1MzowMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OUUzRTY4RjkwRDE2MTFFNTk4RDhBRUJFOEZGMEY0MTIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OUUzRTY4RkEwRDE2MTFFNTk4RDhBRUJFOEZGMEY0MTIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5RTNFNjhGNzBEMTYxMUU1OThEOEFFQkU4RkYwRjQxMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5RTNFNjhGODBEMTYxMUU1OThEOEFFQkU4RkYwRjQxMiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PrzscgwAAAA2UExUReDg4PDw8OHh4e/v7+7u7uLi4uzs7OTk5Ofn5+Xl5enp6ejo6Orq6t/f3+Pj4+bm5u3t7evr63Je6YUAAACXSURBVHjanJJbDsMgDAS9IQRCQh/3v2xJicq4VaWq/kAaYS/eFTbNIdqrkpYBtmkDLUoDYpinQat2NO66DvD6F93QmH/Xv6NRokRpZ/1YnvNuBb5SlN+E7D+h40pndQFAixHQ5gAxdKi12xw3R4yApz5i9BnIxWiMEWCrfats/AOcl7OIVwYULJphIcHpaVQuK8QhPQQYABkSAulC7emeAAAAAElFTkSuQmCC');
      background-position: bottom right;
      background-repeat: no-repeat;
      border: 0;
      color: $primary-text;
      cursor: pointer;
      font-family: $font-family;
      font-size: $font-size;
      line-height: $breadcrumb-strip-height;
      min-width: $breadcrumb-button-min-width;
      outline: 0;
      overflow: hidden;
      padding: 0 0 0 20px;
      text-align: left;

      &:hover {

        color: $primary;
      }

      &:focus {

        color: $primary;
      }
    }

    [class*='fa-'] {

      float: right;
      line-height: $breadcrumb-strip-height;
      margin-right: 20px;
    }

    .breadcrumb-menu {

      background: $background;
      border: 0;
      border-bottom: 3px solid $primary-alt;
      font-size: $font-size;
      margin-left: -10px;

      .breadcrumb-menu-item {

        .breadcrumb-menu-item-link {

          padding: 4px 10px 0;

          &:hover,
          &.breadcrumb-menu-item-link-active {

            color: $primary;

            .breadcrumb-menu-item-text {

              color: $primary;
            }
          }

          .breadcrumb-menu-item-text {

            border-bottom: 1px solid $background-alt;
            display: block;
            padding-bottom: 4px;
          }
        }

        &:last-child {

          .breadcrumb-menu-item-text {

            border-bottom: 0;
          }
        }
      }
    }
  }
}

.breadcrumb-wrapper {

  display: inline-block;
  position: relative;
  zoom: 1;

  .fa-caret-down {

    padding-left: 8px;
  }

  .breadcrumb-menu {

    background-color: $background;
    border: solid 1px $tertiary;
    box-sizing: border-box;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 1;

    .breadcrumb-menu-item {

      margin: 0;
      width: auto;

      .breadcrumb-menu-item-link {

        color: $primary-text;
        display: block;
        font-size: $font-size;
        padding: 7px 15px;
        text-align: left;
        text-decoration: none;

        &:hover {

          background: $background;
        }

        .breadcrumb-menu-item-icon {

          float: left;
          font-size: 22px;
          line-height: 22px;
          margin-right: 15px;
          width: 20px;
        }

        .breadcrumb-menu-item-text {

          line-height: 22px;
        }
      }
    }

    &.breadcrumb-menu-hidden {

      display: none;
    }
  }
}
