.custom-checkbox {

  .custom-control-input:disabled + .custom-control-label {
    &::before,
    &::after {
      cursor: default;
    }
  }

  .custom-control-input:not(:checked) + .custom-control-label {

    &:hover::before,
    &:hover::after {
      border: 2px solid $primary-alt;
    }

  }

  .custom-control-label {
    font-weight: normal;
    margin-top: 0;
    user-select: none;

    &::before,
    &::after {
      cursor: pointer;
    }
  }
}
