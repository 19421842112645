.badge {

  background: $background;
  color: $secondary;
  display: inline-block;
  font-size: $font-size;
  font-weight: normal;
  line-height: 1;
  padding: 3px 7px;

  &.primary,
  &.badge-primary {

    background: $primary;
    color: $light-contrast;
  }

  &.secondary,
  &.badge-secondary {

    background: $primary-text;
    color: $light-contrast;
  }

  &.task,
  &.badge-task {

    background: $task;
    color: $light-contrast;
  }

  &.danger,
  &.badge-danger {

    background: $danger-alt;
    color: $light-contrast;

  }

  &.warning,
  &.badge-warning {

    background: $danger-alt;
    color: $light-contrast;

  }

  &.fluid {
    width: 100%;
  }

  &.align-left {
    text-align: left;
  }
}
