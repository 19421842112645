input {
  &[type='radio'] {
    opacity: 0;
    position: absolute;

    &:not(:checked) + label span.radio {

      &:hover::before {
        border: 2px solid $primary-alt;
      }

    }

    &:checked + label span.radio {

      &::after {
        background-color: $select-option-background;
        border: 6px solid $primary-alt;
        border-radius: 50px;
        height: 18px;
        transition: border-color .15s ease-in-out;
        width: 18px;
      }
    }

    &:focus + label span.radio {

      &::before {
        box-shadow: $select-input-focusbox-shadow;
        transition: box-shadow .15s ease-in-out;
      }
    }
  }
}

.radio-container {
  display: inline-block;

  label {
    font-size: $font-size-base;
    font-weight: 300;
    left: 10px;
    padding: 0 20px;
    position: relative;
  }

  span {

    &.radio {
      padding-right: 10px;

      &:hover {
        cursor: pointer;
      }

      &::after {
        height: 6px;
        left: -7px;
        width: 6px;
      }
    }

    &::after {
      bottom: 0;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
    }

    &::before {
      border: 1px solid $input-border-color;
      border-radius: 50px;
      bottom: 0;
      content: '';
      height: 20px;
      left: -8px;
      margin: auto;
      position: absolute;
      top: 0;
      width: 20px;
    }
  }
}
