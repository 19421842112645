$btn-border-radius: 0 !default;
$btn-border-radius-lg: 0 !default;
$btn-border-radius-sm: 0 !default;

$loading-icon-size: 26px !default;

$badge-border-radius: 0 !default;
$input-padding-y: .5em;
$input-padding-x: .625em;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$select-input-border-width: 1px;

$form-group-margin-bottom: 1.3em;
$border-radius: 0 !default;
$custom-control-indicator-size: 1.2rem;
$card-spacer-y: .4em;
$card-spacer-x: 1.25em;
$tooltip-max-width: 410px !default;
