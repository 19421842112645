@import '~brighttalk-branding/brighttalk-colors/_brighttalk-colors-scss.scss';

//Please don't use these colour variables, use the ones specified in the above BrighTALK branding repo

$background: $bt-default !default;
$background-alt: #e0e0e0 !default;

$primary-text: $primary-text !default; //off-black
$primary-text-alt: $bt-black !default; //lighter off-black
$light-contrast: $bt-default !default; //white
$bt-light-blue: $bt-light-blue !default;

$gray-900: $bt-black !default;
$london-grey: $bt-london-grey;

$light-grey: $bt-london-grey;

$primary: $bt-primary !default; //green
$primary-alt: $bt-light-blue !default; //light green

$secondary: $bt-secondary !default; //grey #818181
$secondary-alt: $bt-black!default; //dark grey

$tertiary: $bt-tertiary !default; //light grey #D7D7D7
$tertiary-alt: $secondary !default; //same as secondary (grey) #818181

$task: #F47D20 !default; //orange
$task-alt: #a8792b !default; //dark orange

$error: #f00 !default; //red
$error-alt: #f99 !default; //pink

$danger: $bt-red !default;
$danger-alt: #EB0037 !default;

$card-cap-bg: $bt-london-grey;
$card-border-color: $bt-tertiary;
$card-border-radius: 0;

$input-btn-focus-width: .1rem;

$input-disabled-bg:  $bt-default !default;
$input-focus-border-color: $primary-alt;

$custom-control-indicator-checked-bg: $primary-alt;
$input-color: $primary-text;
$input-border-color: $card-border-color;

$tooltip-bg: $gray-900;

$highlighted-pastel: #E8F8D9;
$highlighted-grey: $bt-lightest-grey;

$content-blue: #4A95D0;

// *******************************************************************
// Required (mainly) by third party components in the component guide
// *******************************************************************
$select-input-border-color: $tertiary;

$select-input-focus-border-color: $input-focus-border-color;
$select-input-focusbox-shadow: 0 0 0 .1rem rgba($primary, .25);
$select-invalid-input-focusbox-shadow: 0 0 0 .1rem rgba($danger, .25);
$select-option-background-focus: $bt-tertiary;
$select-option-background: $light-contrast;
$select-input-invalid-border-color: $danger;
$select-label-background-color: $bt-tertiary;
$select-label-font-color: $primary-text;

$light-green: #66CC00 !default; //green
$green: #227700 !default;
$purple: #9423FC;
$black: #000000;
$blue: #003CB2 !default;