a {

  color: $primary;
  text-decoration: none;

  &:hover {

    text-decoration: underline;
  }
}
