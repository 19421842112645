.tag {

  background-color: $tertiary;
  border: 1px solid darken($tertiary, 5%);
  color: $secondary-alt;
  font-size: $font-size;
  line-height: $line-height-base;
  padding: $badge-padding-y $badge-padding-x;

  .tag-remove {

    @extend .btn;
    @extend .btn-link;

    color: $secondary-alt;
    float: right;
    padding: 0;
  }
}
